import React, { useState, useEffect } from "react";

const GoodbyeMessage = ({
    respuestaFinal,
    randomCode,
    intentosDeSubida,
    subido,
    videoId,
    userId,
    isPlatinum,
    videoName,
    Form1,
    Form2,
    Form3,
    Form4,
    Form5,
    Form6,
    Form7,
    Form8,
    Goodbye1,
    Goodbye2,
    FormErrors1,
    personalizedCompany,
    personalizedPosition,
    personalizedThankYou,
    AccepTerms5,
    AccepTerms6,
    personalizedUrl,
    TyC1,
}) => {
    const getCompanyOrPosition = (str) => {
        if (str && str.endsWith("-input")) {
            return { input: str.slice(0, -6), value: null };
        } else if (str && str.endsWith("-value")) {
            return { input: null, value: str.slice(0, -6) };
        } else {
            return { input: null, value: null };
        }
    };

    const { input: companyInput, value: companyValue } =
        getCompanyOrPosition(personalizedCompany);
    const { input: positionInput, value: positionValue } =
        getCompanyOrPosition(personalizedPosition);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company: companyValue && companyValue.length > 0 ? companyValue : "",
        occupation: positionValue && positionValue.length > 0 ? positionValue : "",
        conference_id: randomCode,
        videoId: videoId,
        userId: userId,
        isPlatinum: isPlatinum,
        videoName: videoName,
    });

    useEffect(() => {
        console.log(
            "companyInput y positionInput",
            companyInput,
            positionInput,
            positionValue,
            companyValue
        );
    }, [companyInput, positionInput]);

    const [emailError, setEmailError] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Abrir el modal automáticamente cuando el componente se monte
        setIsModalOpen(true);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form data:", formData);

        if (!validateEmail(formData.email)) {
            setEmailError(FormErrors1);
            return;
        } else {
            setEmailError("");
        }

        let updatedFormData = { ...formData };
        if (companyInput) {
            updatedFormData.company += `-input=${companyInput}`;
        }
        if (positionInput) {
            updatedFormData.occupation += `-input=${positionInput}`;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/videos/create_contacto/`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatedFormData),
                }
            );

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(
                    `Failed to submit form: ${response.status} ${response.statusText} - ${errorText}`
                );
            }

            const result = await response.json();
            console.log("Form submitted successfully:", result);

            // await sendEmail();
        } catch (error) {
            console.error("Error submitting form:", error);
        }

        setTimeout(() => {
            setIsModalOpen(false);
        }, 300); // Duración de la animación
    };

    const handleCancel = async () => {
        // try {
        //     // Enviar correo electrónico
        //     await sendEmail();
        // } catch (error) {
        //     console.error("Error sending email:", error);
        // }

        setIsModalOpen(false);
    };

    const sendEmail = async () => {
        let company = formData.company;
        let occupation = formData.occupation;

        if (companyInput) {
            company += `-input=${companyInput}`;
        }

        if (positionInput) {
            occupation += `-input=${positionInput}`;
        }

        const emailData = {
            subject: `New responses at the conference ${videoName} from TrustReel`,
            conference: randomCode,
            name: formData.name || "",
            email: formData.email || "",
            company: company || "",
            position: occupation || "",
            platinum: isPlatinum,
            videoName: videoName || "",
            recipient: "colmenaresjhonny24@gmail.com",
        };

        const emailResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/videos/send_email`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(emailData),
            }
        );

        // fetchVideosList();

        if (!emailResponse.ok) {
            throw new Error("Failed to send email");
        }

        const emailResult = await emailResponse.json();
        console.log("Email sent successfully:", emailResult);
    };

    const [videoUrls, setVideoUrls] = useState([]);
    const [combinedVideoUrl, setCombinedVideoUrl] = useState("");
    // const fetchVideosList = async () => {
    //     const url = `https://storage.bunnycdn.com/trustreel/${userId}/${videoId}/${randomCode}/`;
    //     try {
    //         const response = await fetch(
    //             `${process.env.REACT_APP_BACKEND_URL}/videos/videoslist`,
    //             {
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     accept: "application/json",
    //                     url: url,
    //                 },
    //             }
    //         );

    //         if (!response.ok) {
    //             throw new Error("Failed to fetch videos");
    //         }

    //         const files = await response.json();

    //         const urls = files.map(
    //             (file) =>
    //                 `https://trustreel.b-cdn.net/${userId}/${videoId}/${randomCode}/${file.ObjectName}`
    //         );
    //         const combinedUrl = `https://storage.bunnycdn.com/trustreel/${userId}/${videoId}/${randomCode}/combined.mp4`;
    //         setVideoUrls(urls);
    //         setCombinedVideoUrl(combinedUrl);
    //     } catch (error) {
    //         console.error("Error fetching videos:", error);
    //     }
    // };

    // useEffect(() => {
    //     console.log("Lista de enlaces de video", videoUrls);
    // }, [videoUrls]);

    // const fetchVideoCombined = async (videoUrls, combinedUrl) => {
    //     const data = {
    //         video_urls: videoUrls,
    //         upload_url: combinedUrl,
    //     };

    //     try {
    //         const response = await fetch(
    //             `${process.env.REACT_APP_BACKEND_URL}/videos/process_videos`,
    //             {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify(data),
    //             }
    //         );

    //         if (!response.ok) {
    //             throw new Error("Failed to process videos");
    //         }

    //         const result = await response.json();
    //         console.log("Videos processed successfully:", result);
    //     } catch (error) {
    //         console.error("Error processing videos:", error);
    //     }
    // };

    // useEffect(() => {
    //     if (videoUrls.length > 0 && combinedVideoUrl) {
    //         fetchVideoCombined(videoUrls, combinedVideoUrl);
    //     }
    // }, [videoUrls, combinedVideoUrl]);

    // useEffect(() => {
    //     console.log("Lista de enlaces de video", videoUrls);
    // }, [videoUrls]);

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    return (
        <div
            className={`flex flex-wrap h-screen items-center absolute bg-fondo min-w-full max-h-[100vh] ${respuestaFinal ? "animate__animated animate__fadeInUp" : ""
                }`}
        >
            <div className="flex justify-center items-center w-full h-1/2 md:h-full md:p-20 animate__animated animate__fadeInUp">
                {isModalOpen && (
                    <div className="w-10/12 md:w-7/12 lg:w-3/6 animate__animated animate__fadeInUp">
                        <div className="text-center">
                            <h1 className="text-3xl font-semibold text-gray-200">{Form1}</h1>
                            <p className="mt-2 text-gray-300">{Form2}</p>
                        </div>
                        <div className="mt-5">
                            <form onSubmit={handleSubmit}>
                                <div className="relative mt-6">
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="bg-[#1b1b1d] text-gray-200 peer placeholder-transparent h-10 w-full border-b-2 border-b-gray-300 border-t-[#1b1b1d] border-l-[#1b1b1d] border-r-[#1b1b1d] focus:outline-none focus:border-b-2 focus:border-b-gray-500 focus:border-t-[#1b1b1d] focus:border-l-[#1b1b1d] focus:border-r-[#1b1b1d] focus:ring-0"
                                        placeholder="Name"
                                    />
                                    <label
                                        htmlFor="name"
                                        className="pointer-events-none absolute top-0 left-0 origin-left -translate-y-1/2 transform text-sm text-gray-200 opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-200 peer-focus:top-0 peer-focus:pl-0 peer-focus:text-sm peer-focus:text-gray-200"
                                    >
                                        {Form3}
                                    </label>
                                </div>
                                <div className="relative mt-6">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="bg-[#1b1b1d] text-gray-200 peer placeholder-transparent h-10 w-full border-b-2 border-b-gray-300 border-t-[#1b1b1d] border-l-[#1b1b1d] border-r-[#1b1b1d] focus:outline-none focus:border-b-2 focus:border-b-gray-500 focus:border-t-[#1b1b1d] focus:border-l-[#1b1b1d] focus:border-r-[#1b1b1d] focus:ring-0"
                                        placeholder="Email"
                                    />
                                    <label
                                        htmlFor="email"
                                        className="pointer-events-none absolute top-0 left-0 origin-left -translate-y-1/2 transform text-sm text-gray-200 opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-200 peer-focus:top-0 peer-focus:pl-0 peer-focus:text-sm peer-focus:text-gray-200"
                                    >
                                        {Form4}
                                    </label>
                                    {emailError && (
                                        <p className="text-red-500 text-sm mt-1">{emailError}</p>
                                    )}
                                </div>
                                <div className="relative mt-6">
                                    <input
                                        type="text"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        className="bg-[#1b1b1d] text-gray-200 peer placeholder-transparent h-10 w-full border-b-2 border-b-gray-300 border-t-[#1b1b1d] border-l-[#1b1b1d] border-r-[#1b1b1d] focus:outline-none focus:border-b-2 focus:border-b-gray-500 focus:border-t-[#1b1b1d] focus:border-l-[#1b1b1d] focus:border-r-[#1b1b1d] focus:ring-0"
                                        placeholder="Company"
                                    />
                                    <label
                                        htmlFor="Company"
                                        className="pointer-events-none absolute top-0 left-0 origin-left -translate-y-1/2 transform text-sm text-gray-200 opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-200 peer-focus:top-0 peer-focus:pl-0 peer-focus:text-sm peer-focus:text-gray-200"
                                    >
                                        {companyInput && companyInput.length > 0
                                            ? companyInput
                                            : Form5}
                                    </label>
                                </div>
                                <div className="relative mt-6">
                                    <input
                                        type="text"
                                        name="occupation"
                                        value={formData.occupation}
                                        onChange={handleChange}
                                        className="bg-[#1b1b1d] text-gray-200 peer placeholder-transparent h-10 w-full border-b-2 border-b-gray-300 border-t-[#1b1b1d] border-l-[#1b1b1d] border-r-[#1b1b1d] focus:outline-none focus:border-b-2 focus:border-b-gray-500 focus:border-t-[#1b1b1d] focus:border-l-[#1b1b1d] focus:border-r-[#1b1b1d] focus:ring-0"
                                        placeholder="Occupation"
                                    />
                                    <label
                                        htmlFor="occupation"
                                        className="pointer-events-none absolute top-0 left-0 origin-left -translate-y-1/2 transform text-sm text-gray-200 opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-200 peer-focus:top-0 peer-focus:pl-0 peer-focus:text-sm peer-focus:text-gray-200"
                                    >
                                        {positionInput && positionInput.length > 0
                                            ? positionInput
                                            : Form6}
                                    </label>
                                </div>
                                <div className="my-6 flex flex-col sm:flex-row justify-center">
                                    <button
                                        type="submit"
                                        className="w-full sm:w-auto inline-flex justify-center rounded-md mb-4 sm:mb-0 m px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 hover-grow btnx bg-blue-500"
                                    >
                                        {Form7}
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full sm:w-auto inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 hover-grow btnxcancel bg-gray-500"
                                        onClick={handleCancel}
                                    >
                                        {Form8}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {!isModalOpen && (
                    <div className="flex flex-col items-center justify-center h-full animate__animated animate__fadeInUp">
                        <p className="p-4 text-white">
                            {personalizedThankYou && personalizedThankYou.length > 0
                                ? personalizedThankYou
                                : Goodbye1}
                        </p>
                        {/* {intentosDeSubida > 0 && (
                        <div className='flex items-center justify-center col-span-1'>
                            <p className='text-white'>Upload attempts: {intentosDeSubida}</p>
                        </div>
                    )}
                    {subido > 0 && (
                        <div className='flex items-center justify-center col-span-1'>
                            <p className='text-white'>Uploaded parts: {subido}</p>
                        </div>
                    )} */}
                     <div className="p-4 md:p-6">
                        <div className="flex items-center mb-4 pt-2">
                            <input
                                id="default-checkbox"
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                className="w-4 h-4 text-[#f230aa] bg-gray-100 border-gray-300 rounded focus:ring-[#f230aa] dark:focus:ring-[#f230aa] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                htmlFor="default-checkbox"
                                className="ms-2 text-sm font-medium text-white select-none"
                            >
                                {AccepTerms5}{" "}
                                <a href={TyC1} target="_blank" rel="noopener noreferrer" className="text-[#f230aa] font-bold">
                                    {" "}
                                    {AccepTerms6}
                                </a>
                            </label>
                        </div>
                    </div>

                        <a
                            href={isChecked ? (personalizedUrl && personalizedUrl.length > 0 ? personalizedUrl : "/") : "#"}
                            className={`inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto hover-grow btnx ${!isChecked ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                            onClick={(e) => !isChecked && e.preventDefault()}
                        >
                            {Goodbye2}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GoodbyeMessage;
