import React from 'react';

const TermsAndConditions = ({ 
    isChecked,
    setIsChecked,
    setTermsAndConditions,
    personalizedGreeting,
    AccepTerms1,
    AccepTerms2,
    AccepTerms3,
    AccepTerms4,
    AccepTerms5,
    AccepTerms6,
    AccepTerms7, }) => {
    return (
        <div className={`grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 absolute bg-fondo min-h-[100vh] max-h-[100vh]`}>
            <div className="flex items-center justify-center animate__animated animate__fadeInUp">
                <p className='p-10 text-white'>
                {personalizedGreeting && personalizedGreeting.length > 0 ? personalizedGreeting : AccepTerms1}
                </p>
            </div>
            <div className="flex flex-col items-center justify-center animate__animated animate__fadeInUp h-44 md:h-full">
                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 as="h3" className="text-base font-semibold leading-6 text-white ">
                                {AccepTerms2}
                            </h3>
                            <div className="mt-2">
                                <p className="text-base text-white">
                                    {AccepTerms3}
                                </p>
                                {/* <p className="text-sm text-white">
                                    {AccepTerms4}
                                </p>
                                <div className="flex items-center mb-4 pt-2">
                                    <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) => { setIsChecked(e.target.checked); }}
                                        className="w-4 h-4 text-[#f230aa] bg-gray-100 border-gray-300 rounded focus:ring-[#f230aa] dark:focus:ring-[#f230aa] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-white select-none">
                                        {AccepTerms5} <a href='#' className='text-[#f230aa] font-bold'> {AccepTerms6}</a>
                                    </label>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-3 flex flex-col sm:px-6">
                    <button
                        type="button"
                        // className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto   ${isChecked ? 'bg-good' : 'bg-base cursor-not-allowed '}`}
                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto bg-good '}`}
                        // disabled={!isChecked}
                        onClick={() => setTermsAndConditions(true)}
                    >
                        {AccepTerms7}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;