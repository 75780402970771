import React from 'react';

const LoadingScreen = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="up-down-animation">
                <p className="text-4xl font-bold text-white animate__animated animate__fadeInUp">
                    TrustReel
                </p>
                <p className="text-2xl text-white animate__animated animate__fadeInUp">
                    Video Testimonial
                </p>
            </div>
            <div className="w-4/5 max-w-md mt-4">
                <div className="h-2 rounded-full overflow-hidden">
                    <div className="h-full bg-[#f230aa] animate-progress"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;