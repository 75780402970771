// VideoModal.js
import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

/*const replacePort = (url, oldPort, newPort) => {
    return url.replace(`:${oldPort}`, `:${newPort}`);
};*/
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const VideoModal = ({ onClose,customeUrl,videoName,urlExport }) => {
    const [items, setItems] = useState([]);
    //const updatedBackendUrl = replacePort(backendUrl, 8000, "");
    
    useEffect(() => {
        fetchVideos();
    }, [customeUrl]);

    useEffect(() => {
        console.log(items)
    },[items])
    
    const bunnyCdnKey = process.env.REACT_APP_BUNNY_CDN_KEY;
    const fetchVideos = async (userId) => {
      
        const url = `${customeUrl}${videoName}/`;
        console.log(url);
        try {
            const response = await fetch(`${backendUrl}/videos/videoslist`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'url': url,
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch videos');
            }

            const files = await response.json();
            const videoFiles = files; // Filtrar solo archivos de video (mp4)
            // const filteredVideoFiles = videoFiles.filter((_, index) => index % 2 === 0);
            setItems(files);
            console.log(items);
        } catch (error) {
            console.error('Error fetching videos:', error);
        } finally {
            
        }
    };

const urlbase = urlExport.toLowerCase();

const videoRefs = useRef([]);


useEffect(() => {
    items.forEach((item, index) => {
        if (videoRefs.current[index]) {
            const videoUrl = `${urlbase}${videoName}/${item.ObjectName}`;
            const player = videojs(videoRefs.current[index], {
                controls: true,
                autoplay: false,
                preload: 'auto',
                muted: true,
                sources: [{
                    src: videoUrl,
                    type: 'video/mp4'
                }]
            });

            player.on('play', () => {
                player.muted(false);
            });

            return () => {
                if (player) {
                    player.dispose();
                }
            };
        }
    });
}, [items, urlbase, videoName]);


const [contactData, setContactData] = useState(null);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

useEffect(() => {
    const fetchContactData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/videos/create_contacto/?conference_id=${videoName}`);
            if (!response.ok) {
                throw new Error('Failed to fetch video data');
            }
            const data = await response.json();
            setContactData(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    fetchContactData();
}, [videoName]);

const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const extractValues = (str) => {
    const match = str.match(/(.*?)-input=(.*)/);
    if (match) {
        return { original: match[2], value: match[1] };
    }
    return { original: str, value: null };
};


    return (
<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center overflow-auto">
    <div className="bg-white p-4 rounded-lg shadow-lg w-3/4 max-h-full overflow-y-auto">
        <h2 className="text-xl mb-4">Conference {videoName}</h2>
        {Array.isArray(contactData) && contactData.map((contact) => {
                const companyValues = extractValues(contact.company);
                const occupationValues = extractValues(contact.occupation);

                return (
                    <div key={contact.id} className="mb-4 text-left">
                        <p><strong>Name:</strong> {contact.name}</p>
                        <p><strong>Email:</strong> {contact.email}</p>
                        <p><strong>{companyValues.value ? companyValues.original + ':' : 'Company:'}</strong> {companyValues.value || companyValues.original}</p>
                        <p><strong>{occupationValues.value ? occupationValues.original + ':' : 'Position:'}</strong> {occupationValues.value || occupationValues.original}</p>
                    </div>
                );
            })}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {items.map((item, index) => (
                <div key={item.id} className="border p-4 rounded-lg">
                    <p className="overflow-hidden truncate whitespace-nowrap">{item.videoText}</p>
                    {item.ObjectName ? (
                        <div className='h-80'>

                                    <div data-vjs-player>
                                        <video
                                            ref={el => videoRefs.current[index] = el}
                                            className="video-js vjs-default-skin w-full h-full object-cover"
                                            playsInline
                                        />
                                    </div>
                        </div>
                    ) : (
                        <p>Generando video</p>
                    )}
                    <div className='flex justify-center'>
                    <button
                        onClick={() => handleDownload(`${urlbase}${videoName}/${item.ObjectName}`)} 
                        className="flex items-center justify-center mt-2 px-4 py-4 rounded bg-blue-500 text-white"
                        >
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V12.1893L14.4697 10.4697C14.7626 10.1768 15.2374 10.1768 15.5303 10.4697C15.8232 10.7626 15.8232 11.2374 15.5303 11.5303L12.5303 14.5303C12.3897 14.671 12.1989 14.75 12 14.75C11.8011 14.75 11.6103 14.671 11.4697 14.5303L8.46967 11.5303C8.17678 11.2374 8.17678 10.7626 8.46967 10.4697C8.76256 10.1768 9.23744 10.1768 9.53033 10.4697L11.25 12.1893V7C11.25 6.58579 11.5858 6.25 12 6.25ZM8 16.25C7.58579 16.25 7.25 16.5858 7.25 17C7.25 17.4142 7.58579 17.75 8 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H8Z" fill="#fff"></path>
                        </svg>
                        {/* <span className="ml-1">Download</span> */}
                    </button>
                    </div>
                </div>
            ))}
        </div>
        <div className='flex justify-center space-x-4'>
            <button onClick={onClose} className="mt-4 my-4 bg-blue-500 text-white px-4 py-4 rounded">Close</button>
        </div>
    </div>
</div>
    );
};

export default VideoModal;