import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import { AiFillVideoCamera, AiOutlineVideoCamera, AiFillAudio, AiOutlineAudio } from 'react-icons/ai';
import Select from 'react-select';
import { FaCamera, FaMicrophone } from 'react-icons/fa';

const CameraConfig = ({
    isCameraOn,
    setIsCameraOn,
    isMicrophoneActive,
    setIsMicrophoneActive,
    isMobile,
    camaraFrontalTracera,
    setCamaraFrontalTracera,
    devices,
    selectedDevice,
    setSelectedDevice,
    handleJoinClick,
    CamSettings1,
    CamSettings2,
    CamSettings3,
    CamSettings4,
    setSelectedMicrophone,
    selectedMicrophone,
    microphones
}) => {

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const [audioLevel, setAudioLevel] = useState(0);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const microphoneRef = useRef(null);

    useEffect(() => {
        if (selectedMicrophone) {
            navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedMicrophone } })
                .then(stream => {
                    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                    analyserRef.current = audioContextRef.current.createAnalyser();
                    microphoneRef.current = audioContextRef.current.createMediaStreamSource(stream);
                    microphoneRef.current.connect(analyserRef.current);
                    analyserRef.current.fftSize = 256;
                    const bufferLength = analyserRef.current.frequencyBinCount;
                    const dataArray = new Uint8Array(bufferLength);

                    const getAudioLevel = () => {
                        analyserRef.current.getByteFrequencyData(dataArray);
                        const average = dataArray.reduce((a, b) => a + b) / bufferLength;
                        setAudioLevel(average);
                        requestAnimationFrame(getAudioLevel);
                    };

                    getAudioLevel();
                })
                .catch(error => console.error('Error accessing microphone:', error));
        }

        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, [selectedMicrophone]);

    const borderColor = audioLevel > 20 ? 'border-4 border-good' : 'border-4 border-[#1b1b1d]';

    return (
        <div className="flex items-center justify-center min-h-screen max-h-[100vh] bg-pepe" style={{marginTop: '-50px'}}>
            <div className="p-6 mx-auto rounded-lg space-y-6 max-w-[100%]">
                {/* Row 1: Video/Text Container */}
                <div className={`relative ${borderColor} rounded-xl`}>
        <div className="absolute inset-0 border-4 border-transparent pointer-events-none"></div>

                <div className={`flex justify-center items-center ${isCameraOn ? 'bg-inherit' : 'bg-gray-400'} rounded-lg overflow-hidden max-w-[100%] w-[640px] h-[360px]`}>
                    <div className="relative w-full h-full flex justify-center items-center">
                        {isCameraOn ? (
                            <Webcam
                                className={`inset-0 w-full h-full object-cover`}
                                audio={true}
                                muted={true}
                                videoConstraints={{
                                    width: 1280,
                                    height: 720,
                                    facingMode: camaraFrontalTracera,
                                    deviceId: selectedDevice
                                }} />) :
                            (
                                <p className={`absolute inset-0 flex items-center justify-center text-gray-700 text-lg`}>
                                    :(
                                </p>
                            )
                        }
                    </div>
                </div>
                </div>
                {/* Row 2: Two Buttons */}
                <div className="flex justify-left space-x-4">
                    <button onClick={() => setIsCameraOn(!isCameraOn)} className={`mr-2 w-12 h-12 text-white rounded flex items-center justify-center ${isCameraOn ? 'bg-good' : 'bg-danger'}`}>
                        {isCameraOn ? <AiFillVideoCamera className="" /> : <AiOutlineVideoCamera />}
                    </button>
                    <button onClick={() => setIsMicrophoneActive(!isMicrophoneActive)} className={`w-12 h-12 text-white rounded flex items-center justify-center ${isMicrophoneActive ? 'bg-good' : 'bg-danger'}`}>
                        {isMicrophoneActive ? <AiFillAudio /> : <AiOutlineAudio />}
                    </button>
                </div>


                {/* Row 3: Select Box */}
                <>
                    {isMobile ? (
                        <div>
                        <select
                            className="w-full p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                            onChange={(e) => setCamaraFrontalTracera(e.target.value)}
                            value={camaraFrontalTracera}>
                            <option value="user">{CamSettings1}</option>
                            <option value="environment">{CamSettings2}</option>
                        </select>
                        <select
                                id="microphone-select"
                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 mt-2"
                                onChange={(e) => setSelectedMicrophone(e.target.value)}
                                value={selectedMicrophone}
                            >
                                {microphones.map((microphone) => (
                                    <option key={microphone.deviceId} value={microphone.deviceId}>
                                        {truncateText(microphone.label || `Microphone ${microphone.deviceId}`, 65)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        devices.length > 0 && (
                            <div className='flex flex-col'>
                            <div>
                            <select
                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                                onChange={(e) => setSelectedDevice(e.target.value)} value={selectedDevice}>
                                {devices.map((device, index) => (
                                    <option key={index} value={device.deviceId}>
                                        {device.label || `Camera ${index + 1}`}
                                    </option>
                                ))}
                            </select>
                            </div>
                            <div>
                            <select
                                id="microphone-select"
                                className="w-full p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 mt-2"
                                onChange={(e) => setSelectedMicrophone(e.target.value)}
                                value={selectedMicrophone}
                            >
                                {microphones.map((microphone) => (
                                    <option key={microphone.deviceId} value={microphone.deviceId}>
                                        {truncateText(microphone.label || `Microphone ${microphone.deviceId}`, 65)}
                                    </option>
                                ))}
                            </select>
                            </div>
                            </div>
                        )
                    )}



                </>
                {/* Row 4: Label and Button */}
                <div className="flex justify-between items-center">
                    <label htmlFor="inputField" className="text-gray-400 dark:text-gray-400">{CamSettings3}</label>
                    <button
                        disabled={!isCameraOn || !isMicrophoneActive}
                        onClick={handleJoinClick}
                        className={`ml-4 py-2 px-4 text-white font-semibold rounded-lg ${!isCameraOn || !isMicrophoneActive ? 'bg-disabled cursor-not-allowed' : 'bg-slate-700 hover:bg-good'}`}>
                        {CamSettings4}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CameraConfig;